import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageWithSubNav,
  PageNavigation,
  PageNavigationLink,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { FormModifier, LookupSelect } from 'hudl-uniform-ui-components';
import SectionPlatform from '../partials/_SectionPlatform';
import SectionMobile from '../partials/_SectionMobile';
import OptionGroups from '../partials/_OptionGroups';
import singleSelectPreviewData from '../../../../data/previews/lookup-select.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const options = [
  { label: 'Kareem Abdul-Jabbar', value: 'kareem' },
  { label: 'Marv Albert', value: 'marv' },
  { label: 'Al Attles', value: 'al' },
  { label: 'Red Auerbach', value: 'red' },
  { label: 'Elgin Baylor', value: 'elgin' },
  { label: 'Dave Bing', value: 'dave' },
  { label: 'Larry Bird', value: 'larry' },
];

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Lookup Select"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Selects"
        tierThree="Lookup Select"
      />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>
          Consider interface density and the select’s position when choosing
          between the three available sizes. (Sizing itself is controlled by the{' '}
          <Link href="/components/forms/form-modifier" isDesignCodeLink>
            form modifier component
          </Link>
          .)
        </Paragraph>
        <ComponentPreview
          name="SingleSelectSize"
          layout="halfWidth"
          previewData={singleSelectPreviewData.sizes}>
          <FormModifier>
            <LookupSelect
              ignoreThisComponent
              placeholder="Find an athlete"
              options={options}
            />
          </FormModifier>
        </ComponentPreview>
      </Section>
      <SectionMobile />
      <Section title="Usage">
        <SectionSubhead>List Length</SectionSubhead>
        <Paragraph>
          Lookup select is ideal for lists with more than 15 items, but be
          careful not to overdo it. (For a smaller list, try a{' '}
          <Link href="/components/selects/single-select" isDesignCodeLink>
            single select
          </Link>
          .)
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="give them thousands of items to choose from."
            img="selects-lookuplistlength-dont"
          />
          <DontDoItem
            type="do"
            text="make the list a reasonable size for users to filter."
            img="selects-lookuplistlength-do"
          />
        </DontDo>

        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Option content should be familiar to the user–they should have an idea
          of what’s in the list before opening the select. That familiarity
          helps them decide what to type.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="frame every item differently in an unhelpful order."
            img="selects-lookuporder-dont"
          />
          <DontDoItem
            type="do"
            text="provide a logical list of related items."
            img="selects-lookuporder-do"
          />
        </DontDo>

        <OptionGroups type="lookup" />

        <SectionSubhead>List Height</SectionSubhead>
        <Paragraph>
          Allow enough space in your interface or adjust the max-height to
          prevent the option list from expanding beyond the screen.
        </Paragraph>
        <Paragraph>
          By default, 6.5 items will show when the option list is expanded. If
          you use option groups, adjust the max-height to ensure it falls on a ½
          item to signal the user can scroll.
        </Paragraph>

        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="allow overflow to land between options and give the impression all options are visible."
            img="selects-lookupoverflow-dont"
          />
          <DontDoItem
            type="do"
            text="size the maximum height to split an option and make scrolling obvious."
            img="selects-lookupoverflow-do"
          />
        </DontDo>
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Placeholder</SectionSubhead>
        <Paragraph>
          Lookup select placeholders should read “Find a/an __”. <em>Find</em>{' '}
          implies they can and should take a more active approach by typing to
          generate results. Just like single selects, the article a/an makes it
          clear they’re only choosing one.
        </Paragraph>

        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Lookup select options are typically single words or names. If your
          options go beyond that, be sure to follow the{' '}
          <Link href="/words/content-elements/labels-non-label-text#Non-Label%20UI%20Text">
            non-label text guidelines
          </Link>
          .
        </Paragraph>
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
